import * as actionTypes from '../constants/index';
const userMenuState = localStorage.getItem("userMenu") ? JSON.parse(localStorage.getItem("userMenu")) : []
const userInfo = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.SET_USERINFO:
			return action.data;
		default:
			return state;
	}
};

const userMenu = (state = userMenuState, action) => {
	switch (action.type) {
		case actionTypes.SET_USERMENU:
			return action.data
		default:
			return state
	}
}

export { userInfo, userMenu };
