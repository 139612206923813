import * as actionTypes from '../constants/index';
const setUserInfo = (data) => {
    return {
        type: actionTypes.SET_USERINFO,
        data
    }
};

const setUserMenu = (data) => {
    return {
        type: actionTypes.SET_USERMENU,
        data
    }
};

export { setUserInfo, setUserMenu };
