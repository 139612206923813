import { combineReducers } from 'redux';
import { userInfo, userMenu } from './userInfo';
import tagList from './tagList';
import { breadCrumb, tags, theme, collapse } from './setting';
export default combineReducers({ 
    userInfo, 
    userMenu, 
    tagList, 
    breadCrumb, 
    tags, 
    theme, 
    collapse 
});
